import { useDispatch } from "react-redux";

export const useQuestionsForm = () => {
  const dispatch = useDispatch();

  const dispatchToggle = event => {
    if (event.target.name === "arrivalOn") {
      return dispatch({
        type: "CHANGE_FORM_VALUE",
        payload: { [event.target.name]: event.target.id }
      });
    }
    return dispatch({
      type: "CHANGE_FORM_VALUE",
      payload: { [event.target.name]: event.target.checked }
    });
  };

  const dispatchValue = event =>
    dispatch({
      type: "CHANGE_FORM_VALUE",
      payload: { [event.target.name]: event.target.value }
    });

  const handleInputChange = event => {
    event.persist();
    const { name } = event.target;
    const dispatchChange =
      name === "withPartner" || name === "arrivalOn"
        ? dispatchToggle
        : dispatchValue;
    dispatchChange(event);
  };

  return {
    handleInputChange
  };
};
