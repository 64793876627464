import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import * as firebase from "firebase/app";
import "firebase/database";

import ringSpinner from "../assets/info/RingSpinner.svg";
import lenka from "../assets/players/lenka.png";
import dan from "../assets/players/dan.png";
import { useQuestionsForm } from "../hooks/useQuestionsForm";


// const config = {
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASE_URL,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
// };

const config = {
  apiKey: 'AIzaSyBMnoLp4TDogmOzUIA2hU18VXd3VDOUj4c',
  authDomain: 'https://urbanwedding-cz.firebaseio.com/',
  databaseURL: 'https://urbanwedding-cz.firebaseio.com/',
  projectId: 'urbanwedding-cz',
  storageBucket: 'urbanwedding-cz.appspot.com',
  messagingSenderId: 1005671472567,
};

const firebaseApp = firebase.initializeApp(config);

export const QuestionsForm = () => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const { isSubmitted, inputs } = useSelector(state => state.form);
  const {
    name,
    surname,
    withPartner,
    accommodation,
    arrivalOn,
    childrenCount,
    transportation,
    comment
  } = inputs;

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    setIsError(false);
    if (firebaseApp) {
      const { key } = await firebaseApp
        .database()
        .ref("guests")
        .push({
          ...inputs,
          createdAt: Date.now()
        });
      if (!key) {
        setIsError(true);
        setIsSubmitting(false);
        return;
      }
      dispatch({
        type: "IS_SUBMITTED",
        payload: true
      });
      setIsSubmitting(false);
    }
  };

  const { handleInputChange } = useQuestionsForm();

  const renderQuestionsForm = () => (
    <form className="form-wrap">
      <div className="input-group">
        <label htmlFor="name">Jméno</label>
        <input
          type="text"
          name="name"
          id="name"
          value={name}
          onChange={handleInputChange}
          title="Jméno"
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="surname">Příjmení</label>
        <input
          type="text"
          name="surname"
          id="surname"
          value={surname}
          onChange={handleInputChange}
          title="Příjmení"
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="withPartner">Přijedu s partnerem / partnerkou</label>
        <input
          type="checkbox"
          name="withPartner"
          id="withPartner"
          value={withPartner}
          onChange={handleInputChange}
          title="Partner"
        />
      </div>
      <div className="input-group">
        <label htmlFor="accommodation">Ubytování</label>
        <select
          name="accommodation"
          id="accommodation"
          value={accommodation}
          onChange={handleInputChange}
          title="Ubytování"
          required
        >
          <option value="ROOM">Pokoj</option>
          <option value="HUT">Chatka</option>
          <option value="DONTCARE">Je mi to jedno</option>
          <option value="OWN">Vlastní (např. stan)</option>
        </select>
      </div>
      <div className="input-group">
        <label>Přijedu </label>
        <div>
          <label htmlFor="arrivalOnFriday"> v pátek </label>
          <input
            type="radio"
            name="arrivalOn"
            id="FRIDAY"
            value={arrivalOn}
            checked={arrivalOn === "FRIDAY"}
            onChange={handleInputChange}
            title="Pátek"
            required
          />
        </div>
        <div>
          <label htmlFor="arrivalOnSaturday"> v sobotu </label>
          <input
            type="radio"
            name="arrivalOn"
            id="SATURDAY"
            value={arrivalOn}
            onChange={handleInputChange}
            checked={arrivalOn === "SATURDAY"}
            title="Sobota"
            required
          />
        </div>
      </div>
      <div className="input-group">
        <label htmlFor="childrenCount">Počet dětí</label>
        <input
          type="text"
          name="childrenCount"
          id="childrenCount"
          value={childrenCount}
          onChange={handleInputChange}
          title="Počet dětí"
          required
        />
      </div>
      <div className="input-group">
        <label htmlFor="transportation">Doprava</label>
        <select
          name="transportation"
          id="transportation"
          value={transportation}
          onChange={handleInputChange}
          title="Doprava"
          required
        >
          <option value="CAR">Autem jak pán</option>
          <option value="PUBLIC_TRANSPORT">Sockou</option>
          <option value="OTHER">Jinak (např. pěšky)</option>
        </select>
      </div>
      <div className="input-group">
        <label htmlFor="comment">Poznámky</label>
        <textarea
          name="comment"
          id="comment"
          value={comment}
          onChange={handleInputChange}
          title="Poznámky"
        />
      </div>
      <button className="submit-button" type="submit" onClick={handleSubmit}>
        Odeslat
      </button>

      {isError && (
        <div>
          Chyba při odesílání, zkus to prosím znovu{" "}
          <span role="img" aria-label="sweat smile emoji">
            😅
          </span>{" "}
        </div>
      )}
    </form>
  );

  const renderLoadingSpinner = () => (
    <div>
      Odesílám...
      <div className="rotate">
        <img src={ringSpinner} alt="ring-spinner" />
      </div>
    </div>
  );

  if (isSubmitted) {
    return (
      <div style={{ margin: "2rem" }}>
        Děkujeme za vyplnění
        <div
          style={{
            marginTop: "1rem",
            justifyContent: "center",
            flexDirection: "row",
            display: "flex"
          }}
        >
          <div
            style={{
              backgroundImage: `url(${lenka})`,
              width: `32px`,
              height: `32px`
            }}
            alt="bride"
          />
          <div
            style={{
              backgroundImage: `url(${dan})`,
              width: `32px`,
              height: `32px`
            }}
            alt="groom"
          />
        </div>
      </div>
    );
  }
  return isSubmitting ? renderLoadingSpinner() : renderQuestionsForm();
};
