import React, { useState, useRef, useEffect } from "react";
import { TILE_SIZE } from "./constants";
import arrow from "../assets/arrow.png";
import soundOff from "../assets/info/soundOff.svg";
import soundOn from "../assets/info/soundOn.svg";
import { useButtonForMovements } from "../hooks/useKeyForMovement";
import { useDispatch } from "react-redux";
import { useAudio } from "../hooks/useAudio";

const ROTATIONS = {
  none: undefined,
  NORTH: "rotate(270deg)",
  EAST: "rotate(0deg)",
  SOUTH: "rotate(90deg)",
  WEST: "rotate(180deg)"
};

export const Controls = ({ characters, isTransposed }) => {
  const dispatch = useDispatch();
  const onMove = useButtonForMovements(characters);
  const { isPlaying, handleAudioToggle, setBirds } = useAudio();
  useEffect(() => {
    setBirds();
    return () => handleAudioToggle(true);
  }, []);

  const handleMove = type => {
    return type === "none" ? undefined : onMove(type);
  };

  const renderTile = (type, idx) => {
    return (
      <div className="control-button" key={`${idx}-${type}`}>
        <div
          style={{
            width: TILE_SIZE,
            height: TILE_SIZE,
            backgroundImage: type === "none" ? undefined : `url(${arrow})`,
            transform: ROTATIONS[type]
          }}
          onClick={() => handleMove(type)}
        />
      </div>
    );
  };

  const goToInfoPage = () => {
    dispatch({
      type: "FINISH_LVL"
    });
    dispatch({
      type: "THUG_PLAYED"
    });
    isPlaying && handleAudioToggle();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: isTransposed ? "column" : "row",
        height: isTransposed ? "576px" : "auto",
        width: isTransposed ? "auto" : "576px"
      }}
    >
      <div>
        <h6 className="no-margin">Mise:</h6>
        <h4 className="no-margin">Doveď Dana k Lence</h4>
      </div>

      {/* <button className="controls" onClick={handleAudioToggle}>
        <img
          className="icon"
          src={isPlaying ? soundOff : soundOn}
          alt="sound on sound off"
        ></img>
      </button> */}

      <button className="controls" onClick={goToInfoPage}>
        Přeskočit hru
      </button>

      <div
        style={{
          width: TILE_SIZE * 3,
          height: TILE_SIZE * 3,
          flexWrap: "wrap",
          display: "flex",
          margin: "5px 5px"
        }}
      >
        {[
          "none",
          "NORTH",
          "none",
          "WEST",
          "none",
          "EAST",
          "none",
          "SOUTH",
          "none"
        ].map((tile, idx) => renderTile(tile, idx))}
      </div>
    </div>
  );
};
