import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getAllSpritePosition,
  getAllSpriteStatic
} from "../modules/playerReducer";

const movements = {
  37: "WEST",
  38: "NORTH",
  39: "EAST",
  40: "SOUTH",
  65: "WEST",
  87: "NORTH",
  68: "EAST",
  83: "SOUTH"
};

const formulas = {
  WEST: [0, -1],
  EAST: [0, +1],
  NORTH: [-1, 0],
  SOUTH: [+1, 0],
  NONE: [0, 0]
};

export const useKeyForMovements = characters => {
  const dispatch = useDispatch();
  const { width, height, tiles, goal } = useSelector(state => state.world);
  const positions = useSelector(getAllSpritePosition(characters));
  const isStatic = useSelector(getAllSpriteStatic(characters));

  const handleKeydown = e => {
    e.preventDefault();
    const direction = movements[e.keyCode] || "NONE";
    // eslint-disable-next-line array-callback-return
    characters.map(character => {
      const newPosition = boundaryCheck(
        formulas[direction],
        positions[character],
        isStatic[character],
        height,
        width,
        tiles
      );

      if (newPosition[0] === goal[0] && newPosition[1] === goal[1]) {
        dispatch({ type: "FINISH_LVL" });
      }
      dispatch({
        type: `${character.toUpperCase()}_UPDATE_FIGURE`,
        payload: {
          facing: direction,
          position: newPosition
        }
      });
    });
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeydown);
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  });
};

export const useButtonForMovements = characters => {
  const dispatch = useDispatch();
  const { width, height, tiles, goal } = useSelector(state => state.world);
  const positions = useSelector(getAllSpritePosition(characters));
  const isStatic = useSelector(getAllSpriteStatic(characters));

  const onMove = direction => {
    // eslint-disable-next-line array-callback-return
    characters.map(character => {
      const newPosition = boundaryCheck(
        formulas[direction],
        positions[character],
        isStatic[character],
        height,
        width,
        tiles
      );

      if (newPosition[0] === goal[0] && newPosition[1] === goal[1]) {
        dispatch({ type: "FINISH_LVL" });
      }

      dispatch({
        type: `${character.toUpperCase()}_UPDATE_FIGURE`,
        payload: {
          facing: direction,
          position: newPosition
        }
      });
    });
  };
  return onMove;
};

const boundaryCheck = (
  formula,
  oldPosition,
  isStatic,
  maxHeight,
  maxWidth,
  tiles
) => {
  const newPosition = [
    oldPosition[0] + formula[0],
    oldPosition[1] + formula[1]
  ];
  if (isStatic) {
    return oldPosition;
  }
  if (newPosition[0] < 0 || newPosition[1] < 0) {
    return oldPosition;
  }
  if (newPosition[0] >= maxHeight || newPosition[1] >= maxWidth) {
    return oldPosition;
  }
  if (tiles[newPosition[0]][newPosition[1]] > 3) {
    return oldPosition;
  }
  return newPosition;
};
