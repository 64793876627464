import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { useSelector } from "react-redux";

import { World } from "./components/World";
import { InfoPage } from "./components/InfoPage";
import { ThugLife } from "./components/ThugLife";

import { lvl1 } from "./assets/maps/lvl1";

function App() {
  const { finished, thugPlayed } = useSelector(state => state.progress);
  const [timeLeft, setTimeLeft] = useState(12);

  useEffect(() => {
    if (!finished) {
      return;
    }
    if (!timeLeft) {
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft, finished]);

  const AfterFinishPage = thugPlayed && finished ? InfoPage : ThugLife;
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {finished ? <AfterFinishPage time={timeLeft} /> : <World lvl={lvl1} />}
      <footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          fontSize: "0.5rem",
          marginTop: "1rem",
          marginBottom: "1rem"
        }}
      >
        <div>
          By DaLe with{" "}
          <span role="img" aria-label="love">
            ❤️
          </span>
        </div>
        <div>
          Thanks <a href="https://www.deviantart.com/carmenmcs">CARMENMCS</a>{" "}
          for figures{" "}
          <span role="img" aria-label="thanks">
            🙏
          </span>
        </div>
      </footer>
    </div>
  );
}

export default App;
