import React, { useEffect } from "react";
import dale from "../assets/info/dale.jpeg";
import thug from "../assets/info/thug.png";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useDispatch } from "react-redux";
import { useAudio } from "../hooks/useAudio";

export const ThugLife = ({ time }) => {
  const disptach = useDispatch();
  const { isPlaying, handleAudioToggle, setThug } = useAudio();
  useEffect(() => {
    setThug();
    !isPlaying && handleAudioToggle();
  }, []);

  if (time <= 0) {
    disptach({
      type: "THUG_PLAYED"
    });
    isPlaying && handleAudioToggle();
  }

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%"
      }}
    >
      <div
        className="thug-wrap"
        style={{
          width: windowWidth < windowHeight ? "100%" : "50%"
        }}
      >
        <img className="imgt-hug" src={dale} alt="dale" />
        <img className="imgt-hug fadein" src={thug} alt="thug" />
        <img src={thug} alt="" />
      </div>
      <h4 className="no-margin">Mise splněna!</h4>{" "}
      <h6 className="no-margin">Web se odemkne za {time} sec!</h6>
    </div>
  );
};
