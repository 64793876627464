import { useState, useRef } from "react";
import birdSound from "../assets/birds.mp3";
import ThugLifeSound from "../assets/ThugLifeSound.mp3";

export const useAudio = () => {
  const [isPlaying, togglePlay] = useState(false);
  const audio = useRef(new Audio());

  const setBirds = () => {
    audio.current.src = birdSound;
  };
  const setThug = () => {
    audio.current.src = ThugLifeSound;
  };

  const handleAudioToggle = switchOff => {
    if (switchOff) {
      return audio.current.pause();
    }
    isPlaying ? audio.current.pause() : audio.current.play();
    togglePlay(!isPlaying);
  };

  return {
    setBirds,
    setThug,
    isPlaying,
    handleAudioToggle
  };
};
