import React from "react";
import { useDispatch } from "react-redux";
import { QuestionsForm } from "./QuestionsForm";
import salar from "../assets/info/salar.jpeg";
import hanka from "../assets/info/hanka.jpeg";
import dan from "../assets/info/dan.jpg";
import location from "../assets/info/WeddingLocation.svg";
import wedding from "../assets/info/Wedding.svg";
import tent from "../assets/info/Tent.svg";
import food from "../assets/info/Croasan.svg";
import backpack from "../assets/info/Backpack.svg";
import shoe from "../assets/info/Shoe.svg";
import gift from "../assets/info/Gift.svg";
import party from "../assets/info/Party.svg";
import questions from "../assets/info/QuestionMark.svg";
import plane from "../assets/info/Plane.svg";

const INFO_TEXTS = [
  {
    title: "Lokalita",
    icon: location,
    text: [
      "Vše - obřad, hostina i večerní párty se bude odehrávat v rekreačním středisku ",
      <a href="http://sobenak.cz">Sobeňák</a>,
      " - Starý Rožmitál 148"
    ]
  },
  {
    title: "Obřad",
    icon: wedding,
    text: "Obřad se bude konat v 11:00 hodin, prosíme buďte na místě včas :-)"
  },
  {
    title: "Ubytování",
    icon: tent,
    text:
      "Ubytování bude připraveno od pátku do neděle na pokojích v budovách nebo chatkách."
  },
  {
    title: "Občerstvení",
    icon: food,
    text:
      "Ráno před obřadem a v neděli bude připravena snídaně, o svatební den se můžete těšit na oběd a večerní raut"
  },
  {
    title: "Co na sebe",
    icon: shoe,
    text:
      "Celý den budeme na louce mezi stromy a nejvhodnější bude cokoli pohodlného, v čem se budete cítít slavnostně a bude ladit s počasím"
  },
  {
    title: "Co s sebou",
    icon: backpack,
    text:
      "Dobrou náladu, ručník a cokoliv co potřebujete k pohodlí. Na pokojích budou k dispozici lůžkoviny."
  },
  {
    title: "Dary",
    icon: gift,
    text:
      "Víte jak to je... Chystáme se na nějakou dobu odstěhovat do Kanady a budeme vděční za jakýkoliv obnos, který nám pomůže naše plány zrealizovat :-)"
  },
  {
    title: "Párty",
    icon: party,
    text:
      "Jak bude probíhat? Nechte se překvapit, o kvalitní zábavu, dobré jídlo a pití nouze nebude ;-)"
  },
  {
    title: "Příjezd v pátek",
    icon: plane,
    text:
      "Páteční večer můžete využít k seznámení s ostatními hosty, například při večeři v nedalekém Rožmitále, nebo při společných aktivitách v areálu"
  },
  {
    title: "Dotazy",
    icon: questions,
    text: [
      "S jakýmkoliv dalším dotazem se obraťte přímo na ",
      <a href="mailto:jezkovicau@gmail.com?subject=Urbanwedding">nás</a>,
      " nebo na našeho produkčního  ",
      <a href="mailto:pouziboy@gmail.com?subject=Urbanwedding ">
        Pouziho
      </a>,
      " :-) Těšíme se na vás! Lenka & Dan"
    ]
  }
];

const TEXT_LENKA = `Hanka a Lenka se potkaly ve Finsku na studijním pobytu v Oulu. Od té doby spolu lezou po horách, 
hrají deskovky, jezdí na výlety, konzultují, pomáhají si a ochutnávají víno :-)`;

const TEXT_DAN = `Oba Dan - buddy team`;

export const InfoPage = () => {
  const dispatch = useDispatch();
  const resetToGame = () =>
    dispatch({
      type: "RESET_GAME"
    });

  const renderWitnessInfo = (src, title, name, text) => (
    <div className="large-info-box">
      <div className="image-wrap">
        <img src={src} alt={name} />
      </div>
      <div className="info-item-box">
        <h6>{title}</h6>
        <h5>{name}</h5>
        <div className={"text-container"}>{text}</div>
      </div>
    </div>
  );

  const renderAdditionalInfo = (title, icon, text) => (
    <div style={{ width: "18rem" }} key={title}>
      <div className="info-item-box">
        <h6>{title}</h6>
        <img
          style={{ maxWidth: "30%", margin: "1rem" }}
          src={icon}
          alt={`icon-${title}`}
        />
        <div className={"text-container"}> {text} </div>
      </div>
    </div>
  );

  return (
    <div className="main-container">
      <div
        className="header-image"
        style={{
          backgroundImage: `url(${salar})`
        }}
      >
        <h1>Lenka & Dan </h1>
        <h4>12.09.2020</h4>
      </div>
      <div className="delimeter-title">
        <h5>Děkujeme, že budete součástí našeho velkého dne!</h5>
        <h6>DAN & LENKA</h6>
      </div>
      {renderWitnessInfo(hanka, "SVĚDKYNĚ NEVĚSTY", "Hanka Maťejů", TEXT_LENKA)}
      {renderWitnessInfo(dan, "SVĚDEK ŽENICHA", "Dan Pospíšil", TEXT_DAN)}
      <div className="info-box">
        {INFO_TEXTS.map(({ title, icon, text }) =>
          renderAdditionalInfo(title, icon, text)
        )}
      </div>
      <QuestionsForm />
      <button className="submit-button" onClick={resetToGame}>
        Návrat do hry
      </button>
    </div>
  );
};
