export const lvl1 = {
  // tiles: [
  //   [0, 0, 0, 5, 0, 0, 6, 0, 0, 0, 0, 0, 0, 6, 0, 0, 0, 0, 0, 0],
  //   [0, 0, 0, 0, 4, 0, 0, 6, 0, 0, 4, 0, 0, 7, 0, 0, 7, 0, 0, 0],
  //   [0, 5, 2, 0, 3, 8, 0, 0, 0, 6, 0, 8, 0, 0, 0, 0, 8, 0, 0, 0],
  //   [5, 7, 4, 0, 0, 9, 5, 0, 0, 7, 0, 0, 6, 0, 0, 4, 0, 0, 0, 0],
  //   [5, 7, 4, 0, 0, 9, 5, 0, 0, 7, 0, 0, 6, 0, 0, 4, 0, 0, 0, 0],
  //   [5, 7, 4, 0, 0, 9, 5, 0, 0, 7, 0, 0, 6, 0, 0, 4, 0, 0, 0, 0],
  //   [6, 6, 8, 0, 3, 3, 6, 4, 0, 5, 0, 0, 0, 2, 3, 3, 9, 0, 0, 0],
  //   [6, 6, 8, 0, 3, 3, 6, 4, 0, 5, 0, 0, 0, 2, 3, 3, 9, 0, 0, 0],
  //   [6, 6, 8, 0, 3, 3, 6, 4, 0, 5, 0, 0, 0, 2, 3, 3, 9, 0, 0, 0],
  //   [9, 7, 6, 5, 3, 3, 0, 0, 0, 6, 0, 0, 0, 3, 4, 3, 0, 5, 0, 0]
  // ],
  tiles: [
    [0, 0, 0, 5, 0, 0, 6, 0, 0, 0, 0, 0, 0, 6, 0, 0, 0, 0],
    [0, 0, 0, 0, 4, 0, 0, 6, 0, 0, 4, 0, 0, 7, 0, 0, 7, 0],
    [0, 5, 2, 0, 3, 8, 0, 0, 0, 6, 0, 8, 0, 0, 0, 0, 8, 0],
    [5, 7, 4, 0, 0, 9, 5, 0, 0, 7, 0, 0, 6, 0, 0, 4, 0, 0],
    [6, 6, 8, 0, 3, 3, 6, 4, 0, 5, 0, 0, 0, 2, 3, 3, 9, 0],
    [9, 7, 6, 5, 3, 3, 0, 0, 0, 6, 0, 0, 0, 3, 4, 3, 4, 5]
  ],
  goal: [3, 17],
  players: ["LENKA", "DAN"],
  playerSettings: {
    LENKA: {
      position: [3, 16],
      isStatic: true
    },
    DAN: {
      position: [0, 0],
      isStatic: false
    }
  }
};
