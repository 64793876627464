const initialState = {
  maxHeight: 2,
  maxWidth: 2
};

export const worldReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_WORLD": {
      const { width, height, tiles, goal } = action.payload;
      return {
        ...state,
        width,
        height,
        tiles,
        goal
      };
    }
    default:
      return state;
  }
};
