import React from "react";
import { useSelector } from "react-redux";
import dan from "../assets/players/dan.png";
import hanka from "../assets/players/hanka.png";
import jarda from "../assets/players/jarda.png";
import lenka from "../assets/players/lenka.png";
import { getSpriteOrientation } from "../modules/playerReducer";
import { TILE_SIZE } from "./constants";

const PLAYERS = {
  dan,
  hanka,
  jarda,
  lenka
};

export const Player = ({ character }) => {
  const { position } = useSelector(state => state[character]);
  const spriteAnimation = useSelector(getSpriteOrientation(character));

  return (
    <div
      style={{
        position: "absolute",
        top: position[0] * TILE_SIZE + 3,
        left: position[1] * TILE_SIZE + 3,
        backgroundImage: `url(${PLAYERS[character]})`,
        backgroundPosition: spriteAnimation,
        width: `${TILE_SIZE}px`,
        height: `${TILE_SIZE}px`
      }}
    />
  );
};
