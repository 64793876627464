import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { playerReducer } from "./modules/playerReducer";
import { worldReducer } from "./modules/worldReducer";
import { progressReducer } from "./modules/progressReducer";
import { formReducer } from "./modules/formReducer";

const rootReducer = combineReducers({
  dan: playerReducer("DAN"),
  hanka: playerReducer("HANKA"),
  jarda: playerReducer("JARDA"),
  lenka: playerReducer("LENKA"),
  world: worldReducer,
  progress: progressReducer,
  form: formReducer
});

const persistConfig = {
  key: "root",
  storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  let store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
