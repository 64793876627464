import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Player } from "./Player";
import { Map } from "./Map";
import { Controls } from "./Controls";
import useWindowDimensions from "../hooks/useWindowDimensions";

export const World = ({ lvl }) => {
  const dispatch = useDispatch();
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const { tiles, goal, players, playerSettings } = lvl;
  const initWidth = tiles[0].length;
  const initHeight = tiles.length;
  const transposedTiles = tiles[0].map((col, i) => tiles.map(row => row[i]));
  const isTransposed = windowWidth <= windowHeight;

  const setup = {
    height: isTransposed ? initWidth : initHeight,
    width: isTransposed ? initHeight : initWidth,
    tiles: isTransposed ? transposedTiles : tiles,
    goal: isTransposed ? [goal[1], goal[0]] : goal
  };
  useEffect(() => {
    dispatch({
      type: "SET_WORLD",
      payload: setup
    });
    players.map(playerName =>
      dispatch({
        type: `${playerName}_SET_INITIAL_POSITION`,
        payload: { ...playerSettings[playerName], isTransposed }
      })
    );
  }, [lvl]);

  return (
    <>
      <h4 style={{ marginBottom: "0.5rem" }}>THE URBAN ADVENTURES</h4>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: isTransposed ? "row" : "column",
          justifyContent: "space-around"
        }}
      >
        <div
          style={{
            position: "relative",
            margin: "5px 5px"
          }}
        >
          <Map tiles={tiles} characters={players} />
          {players.map(player => (
            <Player key={player} character={player.toLowerCase()} />
          ))}
        </div>
        <Controls isTransposed={isTransposed} characters={players} />
      </div>
    </>
  );
};
