const initialState = {
  finished: false,
  thugPlayed: false
};

export const progressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FINISH_LVL": {
      return {
        ...state,
        finished: true
      };
    }
    case "RESET_GAME": {
      return {
        ...state,
        finished: false
      };
    }
    case "THUG_PLAYED": {
      return {
        ...state,
        thugPlayed: true
      };
    }
    default:
      return state;
  }
};
