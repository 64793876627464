import { TILE_SIZE } from "../components/constants";

const initialState = {
  position: [0, 0],
  facing: "SOUTH",
  walkIndex: 0,
  isStatic: true
};

export const playerReducer = playerName => (state = initialState, action) => {
  switch (action.type) {
    case `${playerName}_SET_INITIAL_POSITION`: {
      const { position, isStatic, isTransposed } = action.payload;
      const pos = isTransposed ? [position[1], position[0]] : position;
      return {
        ...state,
        position: pos,
        isStatic
      };
    }
    case `${playerName}_UPDATE_FIGURE`: {
      if (state.isStatic) {
        return state;
      }
      const { position, facing } = action.payload;
      const { walkIndex: oldIdx } = state;
      return {
        ...state,
        position,
        facing,
        walkIndex: oldIdx === 2 ? 0 : oldIdx + 1
      };
    }
    default:
      return state;
  }
};

const spriteRotation = {
  WEST: 3,
  EAST: 2,
  NORTH: 1,
  SOUTH: 0
};

export const getSpriteOrientation = id => state => {
  const { facing, walkIndex } = state[id];
  return `${walkIndex * TILE_SIZE}px ${spriteRotation[facing] * TILE_SIZE}px`;
};

export const getAllSpritePosition = characters => state =>
  characters.reduce(
    (acc, char) => ({
      ...acc,
      [char]: state[char.toLowerCase()].position
    }),
    {}
  );

export const getAllSpriteStatic = characters => state =>
  characters.reduce(
    (acc, char) => ({
      ...acc,
      [char]: state[char.toLowerCase()].isStatic
    }),
    {}
  );
