import React from "react";
import { useSelector } from "react-redux";
import { TILE_SIZE } from "./constants";
import smrk from "../assets/obstacles/smrk.png";
import smrk2 from "../assets/obstacles/smrk2.png";
import smrk3 from "../assets/obstacles/smrk3.png";
import tree from "../assets/obstacles/tree.png";
import boulder from "../assets/obstacles/boulder.png";
import appleTree from "../assets/obstacles/appleTree.png";
import sunFlover from "../assets/obstacles/sunFlover.png";
import sunFlovers from "../assets/obstacles/sunFlovers.png";
import { useKeyForMovements } from "../hooks/useKeyForMovement";

export const Map = ({ characters }) => {
  const { tiles = [], width, height } = useSelector(state => state.world);
  useKeyForMovements(characters);

  return (
    <div
      style={{
        position: "relative",
        top: "0px",
        left: "0px",
        width: `${width * TILE_SIZE}px`,
        height: `${height * TILE_SIZE}px`,
        backgroundColor: "#56A00B",
        border: "3px solid black",
        flexDirection: "row",
        display: "column",
        boxSizing: "content-box"
      }}
    >
      {tiles.map((row, idx) => (
        <Row key={idx} row={row} />
      ))}
    </div>
  );
};
const OBSTACLES = {
  2: sunFlover,
  3: sunFlovers,
  4: boulder,
  5: smrk,
  6: smrk2,
  7: smrk3,
  8: tree,
  9: appleTree
};

const Tile = ({ tile }) => (
  <div
    style={{
      backgroundImage: `url(${OBSTACLES[tile]})`,
      width: `${TILE_SIZE}px`,
      height: `${TILE_SIZE}px`
    }}
  />
);

const Row = ({ row = [] }) => (
  <div
    style={{
      position: "relative",
      display: "flex",
      flexDirection: "row"
    }}
  >
    {row.map((tile, idx) => (
      <Tile key={idx} tile={tile} />
    ))}
  </div>
);
