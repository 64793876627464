const initialState = {
  inputs: {
    name: "",
    surname: "",
    withPartner: true,
    accommodation: "HUT",
    arrivalOn: "FRIDAY",
    childrenCount: 0,
    transportation: "CAR",
    comment: ""
  },
  isSubmitted: false
};

export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_FORM_VALUE": {
      const newInputValue = action.payload;
      return {
        ...state,
        inputs: {
          ...state.inputs,
          ...newInputValue
        }
      };
    }
    case "IS_SUBMITTED": {
      const isSubmitted = action.payload;
      return {
        ...initialState,
        isSubmitted
      };
    }
    default:
      return state;
  }
};
